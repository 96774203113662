<!-- eslint-disable global-require -->
<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="partnerForm.name"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="partnerForm.email"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Password"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="partnerForm.password"
                type="password"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Password"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="partnerForm.address"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Address"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description "
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="partnerForm.description"
                trim
                placeholder="Description "
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="blocked"
            label-for="blocked"
          >
            <b-form-checkbox
              id="blocked"
              v-model="partnerForm.blocked"
              value="1"
              unchecked-value="0"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-media
          no-body
          class="mb-1"
        >
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                v-img
                rounded
                :src="partnerForm.image"
                height="80"
              />
            </b-link>
            <!--/ image -->
          </b-media-aside>

          <b-media-body>
            <!-- upload button -->
            <b-button
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="selectFile()"
            >
              Upload Image
            </b-button>
            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png"
              :hidden="true"
              plain
              @change="changeProfile($event)"
            />
            <!--/ upload button -->
          </b-media-body>
        </b-media>

        <b-col cols="12">
          <l-map
            ref="map"
            class="map"
            :zoom="zoom"
            :center="[
              position.lat || userLocation.lat || defaultLocation.lat,
              position.lng || userLocation.lng || defaultLocation.lng
            ]"
            style="height: 450px; width: 100%"

            @click="onMapClick"
          >
            <l-tile-layer
              :url="tileProvider.url"
              :attribution="tileProvider.attribution"
            />
            <l-geosearch :options="geoSearchOptions" />
            <l-marker
              v-if="userLocation.lat && userLocation.lng"
              class="user-location"
              :icon="icon"

              :lat-lng.sync="userLocation"
            >
              <l-tooltip
                :content="tooltipCurrentPosition"
                :options="{ permanent: true }"
              />

            </l-marker>
            <l-marker
              v-if="position.lat && position.lng"
              visible
              draggable
              :lat-lng.sync="position"
              :icon="icon"
              @dragstart="dragging = true"
              @dragend="dragging = false"
            >

              <l-tooltip
                :content="tooltipContent"
                :options="{ permanent: true }"
              />
            </l-marker>
          </l-map>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addpartner()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addpartner()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import {
  LMap,
  LMarker,
  LTileLayer,
  LTooltip,
} from 'vue2-leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'
import { icon } from 'leaflet'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeosearch,
  },
  props: {

    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 8.9806,
        lng: 38.7578,
      }),
    },
  },
  data() {
    return {
      loading: false,
      userLocation: {},
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        autoClose: true,
      },

      position: {},
      address: '',
      tileProvider: {
        attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      icon: icon({
        // eslint-disable-next-line global-require
        iconRetinaUrl: require('@/assets/images/icons/marker.png'),
        // eslint-disable-next-line global-require
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        // eslint-disable-next-line global-require
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      }),
      zoom: 18,
      dragging: false,
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      allPermissions: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const partnerForm = ref({
      name: '',
      blocked: 0,
    })
    // eslint-disable-next-line camelcase
    const image = ref('')

    return {
      getValidationState,
      partnerForm,
      image,
    }
  },
  computed: {

    tooltipContent() {
      if (this.dragging) return '...'
      if (this.loading) return 'Loading...'
      return `<strong>${this.address.replace(
        ',',
        '<br/>',
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`
    },
    tooltipCurrentPosition() {
      if (this.dragging) return '...'
      if (this.loading) return 'Loading...'
      return `<strong>lat:</strong> ${
        this.userLocation.lat
      }<br/> <strong>lng:</strong> ${this.userLocation.lng}`
    },
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress()
        this.$emit('input', { position: value, address: this.address })
      },
    },
  },
  mounted() {
    this.showpartner()

    this.$refs.map.mapObject.on('geosearch/showlocation', this.onSearch)
  },
  methods: {
    async getAddress() {
      this.loading = true
      let address = 'Unresolved address'
      try {
        const { lat, lng } = this.position
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`,
        )
        if (result.status === 200) {
          const body = await result.json()
          address = body.display_name
        }
      } catch (e) {
        console.error('Reverse Geocode Error->', e)
      }
      this.loading = false
      return address
    },

    onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng
      this.userLocation = ''
    },
    onSearch(value) {
      const loc = value.location
      this.zoom = 18
      this.position = { lat: loc.y, lng: loc.x }
    },

    // eslint-disable-next-line consistent-return
    toggleAll(value, key) {
      if (this.allSelected[key]) {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.partnerForm.permission.push(item.id)
        })
      } else {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.partnerForm.permission.splice(this.partnerForm.permission.indexOf(item.id), 1)
        })
      }
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.partnerForm.image = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    showpartner() {
      if (this.$route.params.id) {
        axios.get(`admin/partners/${this.$route.params.id}`).then(res => {
          this.partnerForm = res.data.data.partner
          if (navigator.geolocation) {
            // get GPS position
            navigator.geolocation.getCurrentPosition(pos => {
              // set the user location
              if (this.$route.params.id) {
                this.userLocation = {
                  lat: this.partnerForm.latitude,
                  lng: this.partnerForm.longitude,
                }
              } else {
                this.userLocation = {
                  lat: pos.coords.latitude,
                  lng: pos.coords.longitude,
                }
              }

              this.position = this.userLocation
            })
          }
        })
      } else {
        if (navigator.geolocation) {
          // get GPS position
          navigator.geolocation.getCurrentPosition(pos => {
            // set the user location

            this.userLocation = {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            }

            this.position = this.userLocation
          })
        }

        return false
      }
      return true
    },
    addpartner() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('latitude', this.position.lat)
        formData.append('longitude', this.position.lng)

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.partnerForm) {
          if (key !== 'image') {
            formData.append(key, this.partnerForm[key])
          }
        }
        if (this.image) {
          formData.append('image', this.image)
        }
        axios.post(`admin/partners/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'partners' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()
        formData.append('latitude', this.position.lat)
        formData.append('longitude', this.position.lng)
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.partnerForm) {
          if (key !== 'image') {
            formData.append(key, this.partnerForm[key])
          }
        }
        if (this.image) {
          formData.append('image', this.image)
        }

        this.loader = true
        axios.post('admin/partners', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'partners' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">

  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
